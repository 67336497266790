<template lang="pug">
.results-list.mt-4
  v-data-table(v-show="false"
    :headers="headers"
    :list="list"
  )
  app-table(
    :key="$route.query.tab"
    :headers="headers"
    :list="list"
    v-model="activeItems"
    item-key="ID"
    hide-default-footer
    hide-default-header
    :disable-sort="false"
    :server-items-length="list.length"
    :loading="loading"
    multi-sort=false
    :showSelect="false"
    :mobile-breakpoint='NaN'
    :sort-by="sortModel.by"
    :sort-desc="sortModel.desc"
    @update:sort-by="$emit('update:sort-by', $event)"
    @update:sort-desc="$emit('update:sort-desc', $event)"
    class="res-table app-table v-data-table theme--light"
  )
    template(v-slot:item="{item, index, select, isSelected}")
      list-item(
        :item="item"
        :index="index"
        :isSelected="isSelected"
        :select="select"
        :key="item.ID"
        :primaryPagination="primaryPagination"
        @update:willCome="$emit('update:willCome', $event)"
      )

    template(v-slot:footer="{options}")
      .pagination(v-if="pagination")
          div.pagination__more
            v-btn(
              color="primary"
              text
              class="mb-3 mt-3"
              @click="onLoadMoreClick"
              :loading="loading"
              v-if="pagination.next"
              data-test="res-pagination-btn"
            ) load more
          div.pagination__actions
            v-pagination(
                @input="paginationChange"
                :disabled="loading"
                :length="pagination.totalPages"
                v-model="pagination.page"
                :total-visible="8")

</template>

<script>
import {mapGetters, mapActions} from "vuex"
import { RESERVED_EXAM } from '../core/models/resultModel'
import { RESERVED_EXAM_CANDIDATE } from '../core/models/resultCandidateModel'
import CourseService from '@/app/admin/models/courseService'
import paginationMixin from '@/mixins/pagination.mixin'
import ResultSortModel from "../core/models/resultSortModel"
import ResultHeaders from "./ResultHeaders.vue"
import { RESULT } from '../core/models/resultReservedExam'
import { RESULTS_TAB } from '../core/models/resultsTabModel'

export default {
  name: 'ResultList',
  mixins: [paginationMixin],
  props: {
    list: {
      type: Array,
      required: true
    },
    loading: Boolean,
    pagination: Object,
    primaryPagination: Object,
    sortModel: Object
  },

  data: () => ({
    activeItems: []
  }),

  computed: {
    ...mapGetters({
      courses: 'allCourses/items',
      course: 'results/course'
    }),

    headers() {
      return [
        { text: '№', value: '#ss', sortable: false, class: 'text-center', align: 'center' },
        { text: 'Reserved info', value: 'reserved', class: 'text-center', sortable: true, align: 'center' },
        { text: 'Name', value: 'candidate__name', sortable: true, class: 'text-center', align: 'center'},
        { text: 'Number', value: RESERVED_EXAM.RESERVATION_CODE, align: 'center', class: 'text-center', sortable: false  },
        { text: 'Result', value: 'result__results', align: 'center', class: 'text-center', sortable: true},
        { text: 'Status', value: RESULT.STATUS, sortable: false, align: 'center', class: 'text-center' },
        { text: 'Send results', value: 'send_results_emails', sortable: false, align: 'center', class: 'text-center' },
        { text: '', value: 'send_results', sortable: false, align: 'center', class: 'text-center' },
        { text: 'Will come', value: 'will_come', sortable: false, align: 'center', class: 'text-center' },
        { text: 'App id', value: RESERVED_EXAM.APPLICATION_ID, align: 'center', class: 'text-center cell-app-id', sortable: false},
        { text: 'Type', value: RESERVED_EXAM.IS_MIJN_RESERVATION, align: 'center', class: 'text-center', sortable: true },
        { text: 'Category', value: 'product_name', align: 'center', class: 'text-center', sortable: true},
        { text: 'Notes', value: 'notes', align: 'center', sortable: false, class: 'text-center'},
      ]
    }
  },

  methods: {
    ...mapActions({
      updateCourse: 'results/updateCourseValue'
    }),

    initSortModel() {
      this.sortModel = new ResultSortModel(this.$route.query)
    }
  },

  components: {
    ResultHeaders,
    listItem: () => import ('./ResultsListItem.vue'),
    candidateColor: () => import('@/app/admin/modules/candidates/components/CandidateColor.vue'),
    svcToFuncProvider: () => import('@/components/global/SvcProviderToFunc.vue'),
    appTable: () => import('@/components/global/GroupedTable.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    showingProvider: () => import('@/components/renderless/ShowingProvider.vue'),
  }
}
</script>

<style lang="scss" scoped>

.results-list::v-deep {
  .app-table tr td:first-child{
    padding-left: 4px;
  }
  .app-table th {
    border-right: 1px solid $border-color;
    &:last-child, &:first-child{
      border-right: unset;
    }
  }
  .app-table tr td {
    border-right: 1px solid $border-color;
    border-bottom :1px solid $border-color;
  }
}


.table-dates {
  width: 170px;
  line-height: 1.3;
}

.table-date {
  font-size: 10px;
  &_main {
    font-weight: bold;
  }
  &_additional {
    color: $label-color;
    font-weight: 500;
  }
}

</style>

<style lang="scss">
.cell-presentie {
  width: 40px;
  min-width: 40px;
  text-align: center;
  @media (min-width: 992px) {
    width: 60px;
    min-width: 60px;
    text-align: center;
  }
}

.cell-courses-info {
  width: 130px;
  min-width: 130px;
}

.cell-app-id {
  width: 60px;
  min-width: 60px;
}
</style>
