var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"results-list mt-4"},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"headers":_vm.headers,"list":_vm.list}}),_c('app-table',{key:_vm.$route.query.tab,staticClass:"res-table app-table v-data-table theme--light",attrs:{"headers":_vm.headers,"list":_vm.list,"item-key":"ID","hide-default-footer":"","hide-default-header":"","disable-sort":false,"server-items-length":_vm.list.length,"loading":_vm.loading,"showSelect":false,"mobile-breakpoint":NaN,"sort-by":_vm.sortModel.by,"sort-desc":_vm.sortModel.desc},on:{"update:sort-by":function($event){return _vm.$emit('update:sort-by', $event)},"update:sort-desc":function($event){return _vm.$emit('update:sort-desc', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
var select = ref.select;
var isSelected = ref.isSelected;
return [_c('list-item',{key:item.ID,attrs:{"item":item,"index":index,"isSelected":isSelected,"select":select,"primaryPagination":_vm.primaryPagination},on:{"update:willCome":function($event){return _vm.$emit('update:willCome', $event)}}})]}},{key:"footer",fn:function(ref){
var options = ref.options;
return [(_vm.pagination)?_c('div',{staticClass:"pagination"},[_c('div',{staticClass:"pagination__more"},[(_vm.pagination.next)?_c('v-btn',{staticClass:"mb-3 mt-3",attrs:{"color":"primary","text":"","loading":_vm.loading,"data-test":"res-pagination-btn"},on:{"click":_vm.onLoadMoreClick}},[_vm._v("load more")]):_vm._e()],1),_c('div',{staticClass:"pagination__actions"},[_c('v-pagination',{attrs:{"disabled":_vm.loading,"length":_vm.pagination.totalPages,"total-visible":8},on:{"input":_vm.paginationChange},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)]):_vm._e()]}}]),model:{value:(_vm.activeItems),callback:function ($$v) {_vm.activeItems=$$v},expression:"activeItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }